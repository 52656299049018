import React, { Component } from 'react';
import BlogCard from './BlogCard';

import lambdaImage from './images/lambda.jpg'
import awsImage from './images/aws.jpg'
import reactImage from './images/react_logo.jpg'
import codeImage from './images/complexity.jpg'
import typingImage from './images/typing.jpg'
import bugImage from './images/bug.jpg'

import './Blog.css';

class Blog extends Component {
  render() {
    return (
      <div className="blog">
        <div id="blog" className="anchor"></div>
      	<div className="container">
          <div className="row">
            <div className="col-2">
              <ul className="list-group">
                <li className="list-group-item">AWS Universe</li>
                <li className="list-group-item">Production Serverless Architecture</li>
                <li className="list-group-item">Managing Complexity</li>
                <li className="list-group-item">Phantom Software Bugs</li>
                <li className="list-group-item">How and why you should Blog</li>
                <li className="list-group-item">Single Page Web Applications</li>
              </ul>
            </div>
            <div className="col-10">
          		<h1 className="text-center section-title">Blog</h1>

          		<div className="row">
          			<div className="col-md-4 col-lg-4 col-sm-12">
                  <BlogCard image={awsImage} title="AWS Universe" summary="Amazon's AWS team has made it possible to put all your IT eggs in one basket (for better or worse).  Learn what AWS can do for you." />
          			</div>
          			<div className="col-md-4 col-lg-4 col-sm-12">
                  <BlogCard image={lambdaImage} title="Production Serverless Architecture" summary="Real-world serverless applications. A review of ROI, Cost, and Performance" />
          			</div>
          			<div className="col-md-4 col-lg-4 col-sm-12">
                  <BlogCard image={codeImage} title="Managing Complexity" summary="The IT world is every-increasing in complexity.  How are we going to handle it all?" />
          			</div>
          		</div>

          		<div className="row">
          			<div className="col-md-4 col-lg-4 col-sm-12">
                  <BlogCard image={bugImage} title="Phantom Software Bugs" summary="Ever get those bugs that you can't reproduce? Here is a playbook to follow so you can find and squash that bug." />
          			</div>
          			<div className="col-md-4 col-lg-4 col-sm-12">
                  <BlogCard image={typingImage} title="How and why you should Blog" summary="Everybody should blog (at least a little bit).  I'll explain why..." />
          			</div>
          			<div className="col-md-4 col-lg-4 col-sm-12">
                  <BlogCard image={reactImage} title="Single Page Web Applications" summary="The in's and out's of a real SPA website.  Cost, Performance, Scalability, and more..." />
          			</div>
            	</div>
            </div>

          </div>
      	</div>
      </div>
    );
  }
}

export default Blog;
