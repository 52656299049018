import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './Footer.css';

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedIn: false,
      site_name: "blog.cbusick.com"
    }
  }

  render() {
    return (
      <footer id='footer' className='footer'>
        <hr />
        <div className='container mx-auto'>
          <div className='row m-4'>
            <div className='col-sm-4 col-xs-12'>
              <div className='list-group list-group-flush'>
                <Link to="/">
                  <span className='border border-primary list-group-item m-1 rounded'>
                    Home
                  </span>
                </Link>
                <Link to="/contact">
                  <span className='border border-primary list-group-item m-1 rounded'>
                    Contact
                  </span>
                </Link>
              </div>
            </div>
            <div className='col-sm-4 col-xs-12'>
              <div className='list-group list-group-flush'>
              </div>
            </div>
            <div className='col-sm-4 col-xs-12'>
              <div className='list-group list-group-flush'>
                <Link to="/privacy">
                  <span className='border border-primary list-group-item m-1 rounded'>
                    Privacy Policy
                  </span>
                </Link>
                <Link to="/terms">
                  <span className='border border-primary list-group-item m-1 rounded'>
                    Terms of Use
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className='row m-4'>
         <div className='text-center mx-auto'>
          &copy;{(new Date().getFullYear())}, {this.state.site_name}
          <br />
          Created by me (Chris Busick) using React and Bootstrap.
          <br />
          Serverlessly hosted on AWS</div>
        </div>
      </footer>
    );
  }
}

export default Footer;
