import React, { Component } from 'react';
import './Header.css';
import './App.css';

class Header extends Component {
  render() {
    return (
      <div>
      <header className="App-header header">
        <div className="overlay"></div>

        <div className="container mt-5">
          <div className="greetings">
            <h1>A place to learn about technology, software, coding, websites and more</h1>
          </div>
        </div>
      </header>

      </div>
    );
  }
}

export default Header;
