import React, { Component } from 'react';
import NavBarItem from './NavBarItem';
import './NavBar.css';
import { Link } from "react-scroll";


class NavBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedIn: false,
      menu_items: [
          { name: 'Hosting', target: 'hosting' },
          { name: 'Design', target: 'design' },
          { name: 'Testing', target: 'testing' },
          { name: 'Architecture', target: 'architecture' },
          { name: 'Scaling', target: 'scaling' },
          { name: 'Email Marketing', target: 'email-marketing' },
          { name: 'Contact Me', target: 'contact' },
      ]
    }
  }

  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">

        <a href="/">
          <span className="navbar-brand circle-brand">
            <strong>CB</strong>
          </span>
        </a>

        <button className="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link smooth={true} duration={500} offset={-75} to="home">
                <span data-toggle="collapse" data-target=".navbar-collapse.show" className="nav-link">Home</span>
              </Link>
            </li>
          </ul>

          <ul className="navbar-nav ml-auto">
            {
                this.state.menu_items.map((item) =>
                  <NavBarItem name={item.name} target={item.target} />
                )
            }
          </ul>
        </div>
      </nav>
    );
  }
}

export default NavBar;
