import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import createBrowserHistory from 'history/createBrowserHistory'


import ReactGA from 'react-ga';
//import createHistory from 'history/createBrowserHistory';

import './index.css';
import App from './App';
import ScrollToTop from './ScrollToTop'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'font-awesome/css/font-awesome.min.css';

import * as serviceWorker from './serviceWorker';

ReactGA.initialize('UA-131012394-2'); //Unique Google Analytics tracking number
ReactGA.pageview('/');

ReactDOM.render(
    <Router onUpdate={() => window.scrollTo(0, 0)} history={createBrowserHistory()}>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </Router>
    , document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
