import React, { Component } from 'react';
import './NavBar.css';
import { Link } from "react-scroll";


class NavBarItem extends Component {
  render() {
      return <li className="nav-item">
        <Link smooth={true} duration={500} offset={-75} to={this.props.target}>
          <span data-toggle="collapse" data-target=".navbar-collapse.show" className="nav-link">{this.props.name}</span>
        </Link>
      </li>
  }
}

export default NavBarItem;
