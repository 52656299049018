import React, { Component } from 'react';

import './App.css';

import Header from './Header'
import Blog from './Blog'
import ContactMe from './ContactMe'
import Newsletter from './Newsletter'

class Home extends Component {
  render() {
    return (
      <div>
        <Header />
        <Blog />
        <Newsletter />
        <ContactMe />
      </div>
    );
  }
}

export default Home;
