import React, { Component } from 'react';
import ApiService from './services/home_api'

import './Newsletter.css';


class Newsletter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      site_name: 'blog.cbusick.com',
      email_address: '',
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }


  handleSubmit(event) {
    this.persist()
    event.preventDefault();
  }

  persist() {
    ApiService.post('/subscribe', this.state,
      response => {
        this.setState({submitted: true})
      },
      error => {
        console.log(error);
      }
    )

  }
  render() {
    if (this.state.submitted) {
      return <div className="container">
         <div className="alert alert-success m-4">
          Thanks for signing up.
        </div>
      </div>
    } else {
      return <section className="home-newsletter">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
      	      <div className="single">
      		      <h2>Subscribe to our Newsletter</h2>
                <form onSubmit={this.handleSubmit}>
        	        <div className="input-group">
                    <input required type="email" className="form-control form-control-lg" placeholder="YourEmail@email.com" name="email_address" value={this.state.email_address} onChange={this.handleChange} />
                    <span className="input-group-btn">
                      <button className="btn btn-theme" type="submit">Subscribe</button>
                    </span>
                  </div>
                </form>
        	    </div>
            </div>
          </div>
        </div>
      </section>
    }
  }
}

export default Newsletter;
